import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Clients = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up" data-aos-duration="2000" className='w-[85%] mt-8 m-auto grid lg:grid-cols-2 grid-cols-1 p-[34px] border border-custom rounded-2xl bg-[#F8F8FF]'>
      <div className="text-[#687580] lg:text-4xl text-2xl font-bold py-8 w-[75%] p-10 xs:p-2 sm-w-full">
        <p>Many companies rely on our top 1% talent to scale their development teams.</p>
      </div>
      <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 items-center gap-4'>
        <img src="/assets/images/clients/haraay.webp" alt="IClimbs Haraay" className='w-full ' />
        <img src="/assets/images/clients/knack.webp" alt="IClimbs Knack Bevrages" className='w-full ' />
        <img src="/assets/images/clients/ascott.webp" alt="IClimbs Ascott Group" className='w-full ' />
        <img src="/assets/images/clients/stock.webp" alt="IClimbs Stock Tutor" className='w-full ' />
        <img src="/assets/images/clients/pinakin.webp" alt="IClimbs Pinakin" className='w-full ' />
        <img src="/assets/images/clients/music.webp" alt="IClimbs Adyah Music" className='w-full ' />
      </div>
    </div>
  )
}

export default Clients
