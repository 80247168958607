const Technologies = () => {
  const frontendtechstack = [
    {
      imglink: "/assets/images/technologies/frontend/angular.svg",
      alt: "Angular.JS",
      techname: "Angular JS",
    },
    {
      imglink: "/assets/images/technologies/frontend/nextjs.svg",
      alt: "Next JS",
      techname: "Next JS",
    },
    {
      imglink: "/assets/images/technologies/frontend/react.svg",
      alt: "React",
      techname: "React",
    },
    {
      imglink: "/assets/images/technologies/frontend/vuejs.svg",

      alt: "Vue.JS",
      techname: "Vue.JS",
    },
    {
      imglink: "/assets/images/technologies/frontend/tailwindcss.svg",
      alt: "TailwindCSS",
      techname: "TailwindCSS",
    },
    {
      imglink: "/assets/images/technologies/frontend/sass.svg",
      alt: "SASS",
      techname: "SASS",
    },
    {
      imglink: "/assets/images/technologies/frontend/bootstrap.svg",
      alt: "Bootstrap",
      techname: "Bootstrap",
    },
    {
      imglink: "/assets/images/technologies/frontend/javascript.svg",
      alt: "Javascript",
      techname: "Javascript",
    },
    {
      imglink: "/assets/images/technologies/frontend/html.svg",
      alt: "HTML5",
      techname: "HTML5",
    },
    {
      imglink: "/assets/images/technologies/frontend/css.svg",
      alt: "CSS 3",
      techname: "CSS 3",
    },
  ];

  const backendtechstack = [
    {
      imglink: "/assets/images/technologies/backend/postgresql.svg",
      alt: "PostgreSQL",
      techname: "PostgreSQL",
    }, {
      imglink: "/assets/images/technologies/backend/mongodb.svg",
      alt: "MongoDB",
      techname: "MongoDB",
    },
    {
      imglink: "/assets/images/technologies/backend/redis.svg",
      alt: "Redis",
      techname: "Redis",
    },
    {
      imglink: "/assets/images/technologies/backend/mysql.svg",

      alt: "MySQL",
      techname: "MySQL",
    },
    {
      imglink: "/assets/images/technologies/backend/firebase.svg",
      alt: "Firebase",
      techname: "Firebase",
    },
    {
      imglink: "/assets/images/technologies/backend/nodejs.svg",
      alt: "Node.JS",
      techname: "Node JS",
    },
    {
      imglink: "/assets/images/technologies/backend/express.svg",
      alt: "Express JS",
      techname: "Express JS",
    },
    {
      imglink: "/assets/images/technologies/backend/python.svg",
      alt: "Python",
      techname: "Python",
    },
  ];

  const animation = [
    {
      imglink: "/assets/images/technologies/design/aftereffects.svg",
      alt: "After Effects",
      techname: "After Effects",
    },
    {
      imglink: "/assets/images/technologies/design/premiere.svg",
      alt: "Premiere Pro",
      techname: "Premiere Pro",
    },
    {
      imglink: "/assets/images/technologies/design/blender.svg",
      alt: "Blender",
      techname: "Blender",
    },
  ];
  const uidesign = [
    {
      imglink: "/assets/images/technologies/design/figma.svg",
      alt: "Figma",
      techname: "Figma",
    },
    {
      imglink: "/assets/images/technologies/design/illustrator.svg",
      alt: "illustrator",
      techname: "Illustrator",
    },
    {
      imglink: "/assets/images/technologies/design/photoshop.svg",
      alt: "Photoshop",
      techname: "Photoshop",
    },
  ];

  const nativeapp = [
    {
      imglink: "/assets/images/technologies/app/android.svg",
      alt: "Android",
      techname: "Android",
    },
    {
      imglink: "/assets/images/technologies/app/apple.svg",
      alt: "Apple",
      techname: "Apple",
    },
  ];
  const hybridapp = [
    {
      imglink: "/assets/images/technologies/app/react.svg",
      alt: "React Native",
      techname: "React Native",
    },
    {
      imglink: "/assets/images/technologies/app/flutter.svg",
      alt: "Flutter",
      techname: "Flutter",
    },
    {
      imglink: "/assets/images/technologies/app/ionic.svg",
      alt: "Ionic",
      techname: "Ionic",
    },
  ];

  const cms = [
    {
      imglink: "/assets/images/technologies/cms/magento.svg",
      alt: "Magento",
      techname: "Magento",
    },
    {
      imglink: "/assets/images/technologies/cms/shopify.svg",
      alt: "Shopify",
      techname: "Shopify",
    },
    {
      imglink: "/assets/images/technologies/cms/wordpress.svg",
      alt: "WordPress",
      techname: "WordPress",
    },
    {
      imglink: "/assets/images/technologies/cms/joomla.svg",
      alt: "Joomla",
      techname: "Joomla",
    },
    {
      imglink: "/assets/images/technologies/cms/drupal.svg",
      alt: "Drupal",
      techname: "Drupal",
    },
    {
      imglink: "/assets/images/technologies/cms/zoho.svg",
      alt: "ZOHO",
      techname: "ZOHO",
    },
    {
      imglink: "/assets/images/technologies/cms/aem.svg",
      alt: "AEM",
      techname: "AEM",
    },
  ];

  return (
    <>
      <div className="w-11/12 m-auto technologies">
        <div className="xs:text-center md:text-left">
          <section className="w-11/12 m-auto lg:text-left pt-32 lg:w-5/6 sm:w-11/12">
            <div className="m-auto">
              <h1 className="text-4xl font-semibold heading">
                Tools & Technologies
              </h1>
              <p className="pt-4  text-justify md:w-4/5 xs:w-full">
                Our Tools & Technologies section showcases the cutting-edge
                platforms and frameworks we utilize to deliver exceptional IT
                solutions. From advanced development tools to robust cloud
                services, we ensure efficiency, scalability, and innovation in
                every project.
              </p>
            </div>
          </section>
        </div>
        <div className="bg-[#EBF1FF] rounded-3xl mt-5 p-8 xs:p-2 xs:pt-8">
          <div className="w-5/6 m-auto xs:w-11/12  gap-10 flex flex-row justify-between pt-8 bg-[#F7F7F7] lg:p-14  md:p-10 xs:p-5   rounded-3xl">
            <div className="w-2/5 sm:w-11/12 xs:w-11/12 justify-start">
              <h2 className="font-semibold text-[#2d94f3] title">
                Web Development
              </h2>
              <p className="text-justify">
                iClimb designs and builds websites and web apps that keep you
                relevant in the right places gathering interest online.
              </p>
              <div>
                <div className="mt-5 pt-5">
                  <div className="title-folder lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      className="img"
                      src="/assets/images/technologies/icons/frontend.svg"
                      alt="Frontedn Development"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left ">Frontend Development</h2>
                  </div>
                  <br />

                  <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {frontendtechstack.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-5 pt-5">
                  <div className="title-folder lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      className="img"
                      src="/assets/images/technologies/icons/backend.svg"
                      alt="Backend Development"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">Backend Development</h2>
                  </div>
                  <br />
                  <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {backendtechstack.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-5/6 m-auto xs:w-11/12 gap-10 mt-12 flex flex-row justify-between lg:p-14 md:p-10 xs:p-5">
            <div className="w-2/5 sm:w-11/12 xs:w-11/12 justify-start">
              <h2 className="font-semibold text-[#2d94f3] title">
                App Development
              </h2>
              <p className="text-justify">
                If taking your business to higher levels through app development
                has been lingering in your mind then iClimb is the company you
                should trust. We develop mobile apps for Android as well as iOS
                platforms that serve your organization’s needs from start to
                finish.{" "}
              </p>
              <div className="mt-5 pt-5 flex md:flex-row gap-10 xs:flex-col justify-evenly">
                <div>
                  <div className="title-folder flex lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      src="/assets/images/technologies/icons/nativeapps.svg"
                      alt="App Development"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">Native Apps</h2>
                  </div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {nativeapp.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div className="title-folder flex lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      className="img"
                      src="/assets/images/technologies/icons/hybridapp.svg"
                      alt="App Development"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">Hybrid Apps</h2>
                  </div>

                  <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {hybridapp.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="w-5/6 m-auto xs:w-11/12 gamd:p-10 xs:p-58 flex flex-row justify-between bg-[#F7F7F7] rounded-3xl mt-5 lg:p-14 md:p-10 xs:p-5">
            <div className="w-2/5 sm:w-11/12 xs:w-11/12 justify-start">
              <h2 className="font-semibold text-[#2d94f3] title">
                Content Management Systems
              </h2>
              <p className="text-justify">
                CMS supports business growth by simplifying content management, improving website updates, and enhancing user experience. It responds quickly to market trends, supports SEO optimization, and provides positive feedback from reviews. This efficiency and flexibility allows for overall business expansion through increased engagement, conversion rates, and increased productivity.
              </p>
              <div>
                <div className="mt-5 pt-5">
                  <div className="title-folder flex lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      className="img"
                      src="/assets/images/technologies/icons/devops.svg"
                      alt="DevOps Services"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">Technologies</h2>
                  </div>

                  <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {cms.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-5/6 m-auto xs:w-11/12 gap-10 mt-8 flex flex-row justify-between lg:p-14 md:p-10 xs:p-5">
            <div className="w-2/5 sm:w-11/12 xs:w-11/12 justify-start">
              <h2 className="font-semibold text-[#2d94f3] title">
                UI/UX Design
              </h2>
              <p className="text-justify">
                In this customer-centric world, user experience is everything.
                At iClimb, we have an experienced team of UI/UX designers who
                are both talented and creative.
              </p>
              <div className="mt-5 pt-5 flex md:flex-row gap-10 xs:flex-col justify-between">
                <div>
                  <div className="title-folder flex lg:flex lg:flex-row xs:flex-col items-center">
                    <img
                      src="/assets/images/technologies/icons/animation.svg"
                      alt="App Development"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">Animation</h2>
                  </div>
                  <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {animation.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                <div className="title-folder flex lg:flex lg:flex-row xs:flex-col items-center">
                <img
                      className="img"
                      src="/assets/images/technologies/icons/uidesign.svg"
                      alt="UI Design"
                    />
                    <h2 className="font-semibold text-3xl xs:text-center lg:text-left">UI/UX Design</h2>
                  </div>

                  <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 gap-1">
                    {uidesign.map((key) => (
                      <div
                        key={key.techname}
                        className="flex flex-col items-center justify-center h-32 w-28 p-5"
                      >
                        <a className="text-dark-anchor" href={undefined}>
                          <img
                            className="tech-icon h-20 w-20 object-contain mb-2"
                            src={key.imglink}
                            alt={key.alt}
                          />
                          <p className="tech-title text-center">
                            {key.techname}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Technologies;
