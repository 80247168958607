import React, { useState } from "react";
import { Link } from "react-scroll";
import "./HeroBanner.css";
const HeroBanner = () => {
  const [nav, setNav] = useState(false);
  const [loading, setloading] = React.useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  async function submitForm(e) {
    let frm = e.target.form;
    if (!frm) return console.log("misconfigured");
    const action = frm.getAttribute("action");
    const method = frm.getAttribute("method") || "post";
    let payload = {};
    let errors = [];
    frm.querySelectorAll("[name]").forEach((fld) => {
      if (!fld.validity.valid) errors.push(fld);
      if (["checkbox", "radio"].indexOf(fld.type) === -1) {
        payload[fld.name] = fld.value;
        return;
      }
      if (fld.checked) payload[fld.name] = fld.value;
    });
    if (errors.length) {
      errors[0].focus();
      return false;
    }
    localStorage.setItem("data", JSON.stringify(payload.name));
    setloading(true);
    fetch(`https://iclimbs.in${action}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        window.location.replace(`/thankyou`);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <div
        className="bg-cover bg-center lg:p-10 xs:p-2 "
        style={{ backgroundImage: "url('/assets/images/banner/heroBg.webp')" }}
      >
        <div className="bg-[#0d0d0d] bg-opacity-30 flex lg:flex-row xs:flex-col  justify-between w-[95%] m-auto rounded-2xl p-5 xs:mt-5">
          <div className="lg:w-3/5 xs:w-full lg:p-10 xs:text-center lg:text-left">
            <p className="md:w-full w-full text-white xs:text-3xl md:text-4xl lg:text-[50px] lg:leading-relaxed font-bold">
              Strengthening innovation with excellence and expertise
            </p>
            <p className="lg:text-2xl xs:text-lg md:text-xl leading-8 font-normal py-5 lg:pr-12  text-white text-subtitle">
              Get one-stop IT solutions with the expert's advice right now
            </p>
            <div className="flex justify-start xs:justify-center">
              <Link
                to="contact"
                smooth={true}
                offset={-78}
                duration={500}
                onClick={handleNav}
              >
                <button className="button lg:w-[250px] w-full mt-5">
                  Schedule a call
                </button>
              </Link>
            </div>
          </div>
          <div className="lg:w-2/5 xs:w-full m-auto xs:mt-6  lg:p-8 p-6 rounded-3xl bg-white">
            <form
              method={"post"}
              action={"/api/v1/contact/new"}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="grid gap-5 lg:grid-cols-2 sm:grid-cols-1">
                <div className="lg:col-span-1">
                  <div className="form-group">
                    <frfs-newtext
                      label="Your Name"
                      name="name"
                      placeholder="Your Name"
                      required={true}
                      editable={true}
                    />
                  </div>
                </div>
                <div className="lg:col-span-1">
                  <div className="form-group">
                    <frfs-newemail
                      label="Work Email"
                      name="email"
                      placeholder="Work Email"
                      required={true}
                      editable={true}
                    />
                  </div>
                </div>
                <div className="lg:col-span-1">
                  <div className="form-group">
                    <frfs-newtel
                      label="Phone No"
                      name="phone"
                      placeholder="Phone No"
                      required={true}
                      editable={true}
                      cn="in"
                    />
                  </div>
                </div>
                <div className="lg:col-span-1">
                  <div className="form-group">
                    <frfs-newselect
                      name="service"
                      required={true}
                      editable={true}
                      api="https://iclimbs.in/api/v1/purpose/list"
                      label="Service"
                    />
                  </div>
                </div>
                <div className="lg:col-span-2">
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      fontSize: "16px",
                      marginBottom: "3px",
                      textTransform: "capitalize",
                      fontWeight: "bolder"
                    }}
                  >
                    <label
                      htmlFor="message"
                      style={{
                        color: "black",
                      }}
                    >
                      I&apos;m Interested in More about...{" "}
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <br />
                    <textarea
                      name="message"
                      rows="5"
                      required={true}
                      style={{
                        width: "100%",
                        background: "rgba(255, 255, 255, 0.05)",
                        borderRadius: "5px",
                        border: "1px solid #aeaead",
                      }}
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* <div className="captcha-container">
                <enew-captcha path="/images/captcha/" name="captcha" />
              </div> */}
              <div className="button-container">
                <button
                  disabled={loading}
                  onClick={submitForm}
                  className="bg-[#0131B9] px-[50px] py-[10px] hover:bg-[#2282F1] text-sm text-white font-semibold rounded-lg "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="bg-[#D9D9D9] md:px-10 xs:px-0 w-full  flex flex-col lg:flex-row md:pl-20 sm:items-center xs:gap-0 md:gap-5 py-10 lg:py-20">
        <div className="flex flex-col sm:flex-1 flex-none lg:justify-start xs:justify-center items-start gap-[32px] p-12">
          <h1 className="text-3xl md:text-4xl lg:text-[42px] font-normal">
            Our strategy for elevation
          </h1>
          <p className="text-base md:text-lg lg:text-base leading-9 font-normal text-[#25252c]">
            The growth and success of any business are all about strategy and
            specific techniques. With the strategic partnership and
            implementation of techniques, especially automation and
            high-quality, efficient tools, as per the demand of the market, we
            ensure your growth and the elevation of revenue.{" "}
          </p>
          <Link
            to="contact"
            smooth={true}
            offset={-70}
            duration={500}
            onClick={handleNav}
          >
            <button className="bg-[#FAFCFF] sm:m-0 py-[20px] items-center px-[16.5px] rounded-[8px] text-md  text-[#0131B9] mx-auto mb-4 sm:mb-10 hover:bg-[#0131B9] hover:text-white">
              Talk to our experts
            </button>
          </Link>
        </div>
        <div className="sm:flex-1 flex-none flex w-full justify-center">
          <div>
            <img
              src="/assets/images/banner/banner.webp"
              alt="IClimbs Hero Banner"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroBanner;
