import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
const Footers = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      className="flex flex-col justify-between w-full bg-[#F6F7FF] border border-t-[#111] mt-8 lg:pl-20 pl-6 py-[32px]"
    >
      <div>
        <Link
          to="home"
          smooth={true}
          offset={0}
          duration={500}
          onClick={handleNav}
        >
          <img
            className="w-[8rem] sm:w-[11.5rem] mb-8"
            alt="IClimbs logo"
            src="/assets/images/logo/Logo.png"
          />
        </Link>
      </div>
      <div className="flex lg:flex-row xs:flex-col w-5/6">
        <div>
          <h2 className="text-xl font-black">Get in touch</h2>
          <div className="flex flex-col gap-3 pt-5">
            <div className="flex flex-row items-start justify-start gap-2   ">
              <Link
                to="contact"
                smooth={true}
                offset={-40}
                duration={500}
                onClick={handleNav}
              >
                <button className="cursor-pointer py-[8px] px-[14px] bg-white rounded-lg border-[1px] border-solid border-gray-300 hover:bg-[#0131B9] hover:text-white hover:border-[#c0c0c0]">
                  Contact Us
                </button>
              </Link>
              <Link
                to="contact"
                smooth={true}
                offset={-40}
                duration={500}
                onClick={handleNav}
              >
                <button className="cursor-pointer py-[0.475rem] px-[0.812rem] bg-[#0131B9] text-white rounded-lg border-[1px] border-solid hover:bg-[#5b82ec] hover:border-[#778ecc]">
                  Schedule a call
                </button>
              </Link>
            </div>
            <div className="flex flex-row items-start justify-start gap-2 pt-3">
              <img
                loading="lazy"
                alt="IClimbs contact"
                className="w-7"
                src="/assets/images/Icons/call.svg"
              />
              <div className="pl-2">
                <a href="tel:+917015520689">7015520689,</a>
                <a href="tel:+910124-4376471"> 0124-4376471</a>
              </div>
            </div>
            <div>
              <a
                href="mailto:contact@iclimbs.com"
                className="flex items-center mb-6"
              >
                <img
                  src="/assets/images/Icons/mail.svg"
                  alt="IClimbs Email"
                  className="w-7"
                />
                <h5 className="text-black text-base font-normal leading-6 ml-5">
                  contact@iclimbs.com
                </h5>
              </a>
            </div>
            <div >
              <a
                href="https://maps.app.goo.gl/pcP6jGSJUtKvcSnW9"
                className="flex items-center"
              >
                <img
                  src="/assets/images/Icons/location.svg"
                  alt="IClimbs Location"
                  className="w-7"
                />
                <h5 className="text-black text-base font-normal ml-5">
                  Plot-269, Phase IV, Udyog Vihar, Sector 18, Gurugram, Haryana
                  122015
                </h5>
              </a>
            </div>
          </div>
        </div>
        <div>
          <p className="leading-[1.5rem] py-8 font-bold w-4/6 ">
            Get insights from the experts on building and scaling technology
            teams.
          </p>
        </div>
        <div>
          <div className="py-5">
            <h3 className="font-bold py-5 text-xl">Follow us</h3>
            <div className="flex flex-row items-start justify-start gap-[1.062rem]">
              <RouterLink
                to={"https://www.linkedin.com/company/iclimbsystem2016/"}
                target="_blank"
              >
                <img
                  src="/assets/images/Icons/icons8-linkedin.svg"
                  alt="iClimbs LinkedIn"
                />
              </RouterLink>
              <RouterLink
                to={"https://www.facebook.com/people/Iclimbs/61555634143153/"}
                target="_blank"
              >
                <img src="/assets/images/Icons/icons8-facebook.svg" alt="iClimbs Facebook" />
              </RouterLink>
              <RouterLink
                to={
                  "https://www.instagram.com/iclimbsystem/?igsh=bjUxZ2E1b2MydHNq"
                }
                target="_blank"
              >
                <img
                  src="/assets/images/Icons/icons8-instagram.svg"
                  alt="iClimbs Instagram"
                />
              </RouterLink>
              <RouterLink to={"https://pin.it/3BN7Gad0U"} target="_blank">
                <img
                  src="/assets/images/Icons/icons8-pinterest.svg"
                  alt="iClimbs Pinterest"
                />
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footers;
