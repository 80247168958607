import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import "./stories.css"
import Carosel from '../Carosel/Carosel';
const Stories = () => {

    return (
        <div className="testimonial bg-[#F8F8FF] w-5/6 m-auto mt-8 px-4 py-8 rounded-lg" >
            <div className='p-12'>
                <p className='text-4xl pb-4'>Customer Success Stories</p>
                <div className='flex items-center content-center w-2/3'>
                    <p className='font-medium'>Our Greatest Hits</p>
                    <FaArrowRightLong className='text-xl ml-3' />
                </div>

            </div>
            <Carosel />
        </div>
    )
}

export default Stories