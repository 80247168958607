import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./carosel.css"
const Carosel = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const data = [
    {
      banner: "/assets/images/carousel/knackwebsite.webp",
      alt:"Knack Beverages Pvt Ltd",
      des: "IClimb has an outstanding team of experts for the enhancement of business growth, especially with IT support. I would always like to mention its name for all."
    },
    {
      banner: "/assets/images/carousel/stocktutorwebsite.webp",
      alt:"StockTutor",
      des: "IClimb has helped us minimize the maximum cost of whatever we used to bear on IT. It has increased our product reputation as well."

    },
    {
      banner: "/assets/images/carousel/harraywebsite.webp",
      alt:"Haraay Consultancy Pvt Ltd",
      des: "IClimb has proved its reliability by imparting such an outstanding experience for us, especially while establishing our business on the online platform."

    },
    {
      banner: "/assets/images/carousel/ascottwebsite.webp",
      alt:"The Ascott Limited",
      des: "A robust strategy and excellent teamwork at the IClimb have helped us get the best design and IT services while streamlining our business."

    },
    {
      banner: "/assets/images/carousel/adyahmusic.webp",
      alt:"Adyah Music",
      des: "Strong repeat business shows the team's excellence. I'd gladly hire them again. Their collaborative style and quick grasp of complex issues accelerated progress towards our goals."

    }, {
      banner: "/assets/images/carousel/pinakin.webp",
      alt:"Pinakin",
      des: "IClimb is a reliable development partner with deep expertise who adapts seamlessly. Their willingness to challenge assumptions aligns perfectly with our culture, driving even better results."
    },

  ];

  return (
    <div className="w-full h-fit">
      <Slider {...settings}>
        {data.map((item, index) => (
          <div id='slider-boxes' key={index} className=' bg-white slide-item slides'>
            <div className='px-[32px] flex flex-col'>
              <img src={item.banner} alt={`IClimbs ${item.alt}`} />
            </div>
            <div className='p text-wrap w-4/6'>
              <p><span className="text-[#0131B9] text-bold text-2xl items-center text-center">"</span>{item.des}<span className="text-[#0131B9] text-bold text-2xl items-center text-center">"</span></p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carosel;
