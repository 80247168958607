import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <nav
      className="sticky lg:px-[100px]  top-0 left-0 z-10 bg-[#F8F8FF] border-b-[#E0E0E0] shadow-[0px_0px_50px_-10px_rgba(41,41,41,0.08)]"
      style={{ width: "100%" }}
    >
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
        <Link
          to="home"
          smooth={true}
          offset={0}
          duration={500}
          onClick={handleNav}
          className="cursor-pointer"
        >
          <img
            src="/assets/images/logo/Logo.png"
            className="w-[105px]"
            alt="IClimbs Logo"
          />
        </Link>
        <div className="hidden md:flex text-xl gap-8 ">
          <Link
            to="services"
            smooth={true}
            offset={-100}
            duration={500}
            onClick={handleNav}
            className="cursor-pointer text-[#1E1E1E] hover:text-gray-900"
          >

            Services
          </Link>
          <Link
            to="testimonial"
            smooth={true}
            offset={-100}
            duration={500}
            onClick={handleNav}
            className="cursor-pointer text-[#1E1E1E] hover:text-gray-900"
          >

            Testimonials
          </Link>
          <Link
            to="technologies"
            smooth={true}
            offset={-200}
            duration={500}
            onClick={handleNav}
            className="cursor-pointer text-[#1E1E1E] hover:text-gray-900"
          >
            Tech Stack
          </Link>
        </div>
        <div className="hidden md:block">
          <Link
            to={"contact"}
            smooth={true}
            offset={-40}
            duration={500}
            onClick={handleNav}
          >
            <button className="cursor-pointer py-[0.475rem] px-[0.812rem] bg-[#0131B9] text-white rounded-lg border-[1px] border-solid hover:bg-[#5b82ec] hover:border-[#778ecc]">
              Contact Us
            </button>
          </Link>
        </div>
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-600 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div
          data-aos="fade-left"
          data-aos-duration="500"
          className="md:hidden size-full absolute top-0 left-0 w-full min-h-screen bg-[#E2E3F5] z-10 flex flex-col items-center justify-center gap-3"
        >
          <a href="#" className="absolute top-2 left-2">
            <img
              src="/assets/images/logo/logoDark.png"
              alt="IClimbs logo"
              className="w-[105px] h-[43px]"
            />
          </a>
          <button
            onClick={() => setIsOpen(false)}
            className="absolute top-8 right-8 text-gray-600 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <Link
            to="services"
            smooth={true}
            offset={-100}
            duration={500}
            onClick={handleNav}
          >
            <a
              href="#services"
              onClick={() => setIsOpen(false)}
              className="text-gray-600 hover:text-gray-900 text-2xl mb-4"
            >
              Services
            </a>
          </Link>
          <Link
            to="testimonial"
            smooth={true}
            offset={-260}
            duration={500}
            onClick={handleNav}
          >
            <a
              href="#testimonial"
              onClick={() => setIsOpen(false)}
              className="text-gray-600 hover:text-gray-900 text-2xl mb-4"
            >
              Testimonial
            </a>
          </Link>
          <Link
            to="technologies"
            smooth={true}
            offset={-100}
            duration={500}
            onClick={handleNav}
          >
            <a
              href="#technologies"
              onClick={() => setIsOpen(false)}
              className="text-gray-600 hover:text-gray-900 text-2xl mb-4"
            >
              Tech Stack
            </a>
          </Link>
          <Link
            to="contact"
            smooth={true}
            offset={-40}
            duration={500}
            onClick={handleNav}
          >
            <button onClick={() => setIsOpen(false)} className="border border-[#0131B9] px-4 py-2 rounded hover:bg-[#2282F1] hover:text-white hover:border-none">
              Contact Us
            </button>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
