import React from 'react';
import { useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";

const ThankYou = () => {
  const name = JSON.parse(localStorage.getItem("data"))
  const navigate = useNavigate();
  React.useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 10000);
  }, [])
React.useEffect(()=>{
handleClick()
},[])  

  const handleClick = () => {
    const end = Date.now() + 3 * 1000; // 3 seconds
    const colors = ["#a786ff", "#fd8bbc", "#eca184", "#f8deb1"];
 
    const frame = () => {
      if (Date.now() > end) return;
 
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        startVelocity: 60,
        origin: { x: 0, y: 0.5 },
        colors: colors,
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        startVelocity: 60,
        origin: { x: 1, y: 0.5 },
        colors: colors,
      });
 
      requestAnimationFrame(frame);
    };
 
    frame();
  };
 
  return (
    <div className="relative">

    <div className='p-[32px] flex flex-col items-center bg-[#E2E3F5] rounded-lg w-[80%] m-auto'>
      <h1 className='text-[64px] text-[#0131B9]'>Leave It with us “{name}”</h1>
      <p className='text-2xl'>we’ve got your request and we will contact you as soon as possible</p>
      <img src="/assets/images/thanku.webp" alt="" className='px-[32px] py-[45px]' />
      <button
        className='bg-[#0131B9] text-white text-sm font-semibold px-8 py-3 rounded-lg mb-5'
        style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}
        onClick={() => { navigate("/") }}
      >
        Back to website
      </button>
    </div>
    </div>
  );
}

export default ThankYou;
