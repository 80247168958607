import React, { useEffect } from "react";
import "./Approach.css";
import AOS from "aos";
import "aos/dist/aos.css";
const Approach = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-duration="2000"
        className="mt-8 py-[56px] px-[103px] xs:m-auto xs:px-[60px] text-center   bg-[#000000]"
      >
        <div className="flex flex-col gap-4">
          <p className="text-white font-medium text-4xl tracking-[2.1px] underline underline-offset-8 decoration-[#0131B9]">
            Our Approach
          </p>
          <p className="text-white">Simple and easy Process</p>
        </div>
        <div
          className="background flex flex-col justify-start gap-8 mt-10 xs:bg-none lg:p-14"
        >
          <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-10 mb-16">
            <div className="text-white flex flex-col items-center text-center gap-5">
              <h2 className="text-2xl text-[#2282F1]">
                Client-Centric Solutions
              </h2>
              <p className="text-base">
                We prioritize your needs, crafting tailored solutions that drive
                your success.
              </p>
              <div
                className="bg-white justify-center items-center p-[30px] rounded-bl-[60px] rounded-br-[60px]"
                style={{ borderTop: "4px solid #2282F1" }}
              >
                <img
                  src="/assets/images/approach/solution.svg"
                  alt="IClimbs Client-Centric Solutions"
                />
              </div>
              <p className="text-4xl font-bold">01</p>
            </div>
            <div className="text-white flex flex-col items-center text-center gap-5">
              <p className="text-4xl font-bold">02</p>
              <div
                className="bg-white justify-center items-center p-[30px] rounded-tl-[60px] rounded-tr-[60px]"
                style={{ borderBottom: "4px solid #2282F1" }}
              >
                <img
                  src="/assets/images/approach/technology.svg"
                  alt="IClimbs Quality & Excellence"
                />
              </div>
              <p className="text-base">
                Embracing cutting-edge technology, we bring innovative solutions
                to modern challenges.
              </p>
              <h2 className="text-2xl text-[#2282F1]">
                Innovation and Technology{" "}
              </h2>
            </div>
            <div className="text-white flex flex-col items-center text-center gap-5">
              <h2 className="text-2xl text-[#2282F1]">
                Quality and Excellence
              </h2>
              <p className="text-base">
                Upholding the highest standards, we deliver exceptional quality
                in every project.
              </p>
              <div
                className="bg-white justify-center items-center p-[30px] rounded-bl-[60px] rounded-br-[60px]"
                style={{ borderTop: "4px solid #2282F1" }}
              >
                <img
                  src="/assets/images/approach/quality.svg"
                  alt="IClimbs Quality & Excellence"
                />
              </div>
              <p className="text-4xl font-bold">03</p>
            </div>
            <div className="text-white flex flex-col items-center text-center gap-5">
              <p className="text-4xl font-bold">04</p>
              <div
                className="bg-white justify-center items-center p-[30px] rounded-tl-[60px] rounded-tr-[60px]"
                style={{ borderBottom: "4px solid #2282F1" }}
              >
                <img
                  src="/assets/images/approach/collaboration.svg"
                  alt="IClimbs Collaboration and Partnership"
                />
              </div>
              <p className="text-base">
                Collaborative efforts with our partners ensure holistic and
                sustainable solutions.
              </p>

              <h2 className="text-2xl text-[#2282F1]">
                Collaboration and Partnership{" "}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approach;
