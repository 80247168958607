import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Clients from "../components/Clients/Clients";
import Stories from "../components/Stories/Stories";
import Contact from "../components/Contact/Contact";
import Footers from "../components/Footers/Footers";
import Journey from "../components/Journey/Journey";
import HeroBanner from "../components/HeroBanner/HeroBanner.jsx";
import Technologies from "../components/Technologies/Technologies.jsx";
import CaseStudies from "../components/Casestudies/CaseStudies.jsx";
import Approach from "../components/Approach/Approach.jsx";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <HeroBanner />
      <Journey />
      <Approach />
      <CaseStudies />
      <Clients />
      <Stories />
      <Technologies />
      <Contact />
      <Footers />
    </>
  );
};

export default HomePage;
