import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const CaseStudies = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
  };
  const casedetails = [
    {
      img1: "/assets/images/casestudies/stocktutor.png",
      logo: "/assets/images/casestudies/stocktutorlogo.png",
      heading1:
        "Created a highly-rated educational streaming app that provides a best-in-class learning experience.",
      heading2: "Our Solution:",
      details:
        "We are proud to announce the launch of STOCKTUTOR, a revolutionary online platform designed to empower aspiring investors with the knowledge and confidence they need to navigate the stock market. STOCKTUTOR provides a user-friendly and interactive learning environment that caters to diverse learning styles and experience levels.",
      num1: "56%",
      num1detail1: "Increase in Global User Adoption",
      num2: "4.6",
      num2detail2: "Stars Rating on App Stores",
      num3: "30%",
      num3detail3: "Reduction in Customer Churn Rate",
    },
    {
      img1: "/assets/images/casestudies/haraay.png",
      logo: "/assets/images/casestudies/haraaylogo.png",
      heading1:
        "Created a highly-rated online platform for employment platforms",
      heading2: "Our Solution:",
      details:
        "We're excited to unveil Haraay Consultancy, a revolutionary MERN Stack platform that tackles limitations of traditional employment platforms. Built with MongoDB, Express.js, React, and Node.js, Haraay Consultancy delivers unparalleled performance, effortless scalability, an intuitive user experience for both job seekers and employers, and a robust backend for reliable functionality.",
      num1: "51%",
      num1detail1: "Increase in Global User Adoption",
      num2: "4.5",
      num2detail2: "Stars Rating on App Stores",
      num3: "45%",
      num3detail3: "Reduction in Customer Churn Rate",
    },
    {
      img1: "/assets/images/casestudies/knack.png",
      logo: "/assets/images/casestudies/knacklogo.png",
      heading1:
        "Created  E-commerce Experience Knack Beverages: A Refreshing Online Experience",
      heading2: "Our Solution:",
      details:
        "We're thrilled to launch the new Knack Beverages website, built with the MERN Stack. This dynamic platform offers a seamless online experience for beverage enthusiasts: explore curated selections, discover new favorites through intuitive search and personalized recommendations, learn brand stories, and enjoy a frictionless checkout.",
      num1: "60%",
      num1detail1: "Increase in Global User Adoption",
      num2: "4.8",
      num2detail2: "Stars Rating on App Stores",
      num3: "50%",
      num3detail3: "Reduction in Customer Churn Rate",
    },
  ];

  return (
    <>
      <div className="lg:w-4/5 xs:w-full m-auto mt-10">
        <div className="w-full p-10 m-auto">
          <h2 className="text-[#0131B9] text-4xl">Featured Case Studies </h2>
          <p className="mt-5">
            Explore diverse projects that showcase our innovative solutions and
            real-world impact. Each case study highlights unique challenges,
            strategies, and results, demonstrating how our expertise drives
            success. Discover how we’ve transformed businesses and delivered
            value through detailed insights and compelling project outcomes.
          </p>
        </div>
        <Slider {...settings}>
          {casedetails.map((el, index) => (
            <div
              className="bg-[#EBF4FF] lg:w-11/12 xs:w-full rounded-2xl mb-8 pb-5"
              key={index}
            >
              <div className="flex lg:flex-row xs:flex-col bg-[#EBF4FF] justify-evenly p-8 mt-8">
                <div className="lg:w-3/5">
                  <img src={el.img1} alt="iClimb Case Studies" />
                </div>
                <div className="lg:w-4/5 xs:w-full space-y-4">
                  <img src={el.logo} alt="iClimb Case Studies" />
                  <h2 className="text-2xl text-pretty ">{el.heading1}</h2>
                  <h3 className="text-xl font-semibold">{el.heading2}</h3>
                  <h4 className="text-base text-pretty ">{el.details}</h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>

      </div>
    </>
  );
};

export default CaseStudies;
