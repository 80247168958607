import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ThankYou from '../Pages/ThankYou'
import HomePage from '../Pages/HomePage'

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/thankyou' element={<ThankYou />} />
                <Route path='*' element={<Navigate to={"/"} />} />
            </Routes>
        </>
    )
}

export default AllRoutes